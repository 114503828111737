<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" sm="1" class="mb-4">
        <back-button></back-button>
      </b-col>
      <b-col cols="auto" class="mb-4 d-flex align-items-center">
        <b-form-checkbox v-if="bkUser" v-model="filters.allCompanies" class="text-white">
          All Companies
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" sm="10" xl="7" class="mb-4">
        <b-form-group class="m-0">
          <b-select
            v-model="company.id"
            @change="selectCompany"
            :options="dropdownOptions"
            text-field="name"
            value-field="id"
          ></b-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" xl="3" v-if="company.id" class="mb-4">
        <DocumentModal :compId="company.id" :companyName="company.name" @refresh="refresh"></DocumentModal>
      </b-col>
    </b-row>
    <b-row class="mb-4 sticky-filters">
      <b-col cols="12">
        <b-card>
          <b-row align-v="center">
            <b-col cols="12" xl="6" class="text-left">
              <b-form-group label="Year" label-align="left" class="d-inline mr-4">
                <b-form-select v-model="filters.year" :options="years">
                  <template #first>
                    <b-form-select-option :value="null"></b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group label="Month" label-align="left" class="d-inline mr-4">
                <b-form-select
                  :options="months"
                  v-model="filters.month"
                ></b-form-select>
              </b-form-group>
              <b-form-group label="Quarter" label-align="left" class="d-inline mr-4">
                <b-form-select
                  :options="quarters"
                  v-model="filters.quarter"
                ></b-form-select>
              </b-form-group>
              <b-form-group label="Document Type" label-align="left" class="d-inline">
                <b-form-select
                  :options="doctypes"
                  text-field="name"
                  value-field="id"
                  v-model="filters.documenttype_id"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6" class="d-flex justify-content-end">
              <div>
                <b-button
                  v-if="selectedDocuments.length > 0 && (adminUser || bkUser)"
                  variant="danger"
                  class="mr-2"
                  @click="bulkDelete"
                >
                  Delete Selected
                </b-button>

                <b-button
                  v-if="selectedDocuments.length > 0"
                  variant="success"
                  class="mr-2"
                  @click="bulkDownload"
                >
                  Download Selected
                </b-button>

                <b-button variant="info" class="mr-2" @click="search()">Search</b-button>
                <b-button variant="danger" @click="resetfilters()">Reset</b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <div>
      <b-modal v-model="modalShow" title="Preview" :size="'xl'" v-if="isPdf(selectedDocumentUrl)" ok-only ok-variant="danger" ok-title="Close">
        <iframe :src="selectedDocumentUrl" width="100%" height="500px"></iframe>
      </b-modal>
    </div>
    <b-card class="text--black">
      <b-pagination
        class="float-right"
        v-if="filters.totalItems > filters.max"
        v-model="filters.page"
        :total-rows="filters.totalItems"
        :per-page="filters.max"
        aria-controls="documentsTable"
        @change="changePage"
      ></b-pagination>
      <b-table
        id="documentsTable"
        striped
        :items="files"
        :fields="fields"
        no-local-sorting
        :busy="loading"
        show-empty
        @sort-changed="sortingChange"
        sort-desc
        sort-by="updated"
        stacked="lg"
      >
        <template #cell(select)="data">
          <b-form-checkbox v-model="selectedDocuments" :value="data.item.id"></b-form-checkbox>
        </template>
        <template #cell(id)="data">
          <b-button @click="toggleModal(data.item.documenturl)" v-if="data.item.thumbnail"><img :src="data.item.thumbnail"></b-button>
          <b-button @click="toggleModal(data.item.documenturl)" v-else><b-icon icon="file-earmark-text-fill" class="h1">
          </b-icon></b-button>
        </template>

        <template #cell(documenttype_id)="data">
          {{ data.item.name }}
        </template>

        <template #cell(documentname)="data">
          <span @click="toggleModal(data.item.documenturl)" v-if="data.item.documenturl && isPdf(data.item.documenturl)" class="text--sbs-red cursor-pointer">{{ data.item.documentname | docname }}</span>
          <span v-else-if="data.item.downloadurl && !isPdf(data.item.documenturl)" class="text--sbs-red cursor-pointer"><a :href="data.item.downloadurl" class="text--sbs-red cursor-pointer">{{ data.item.documentname | docname }}</a></span>
          <span v-else>{{ data.item.documentname | docname }}</span>
        </template>

        <template #cell(updated)="data">
          {{ data.item.updated | date }}
        </template>

        <template #cell(action)="data">
          <div v-if="data.item.documenttype_id != 15"> <!-- Cannot edit or delete contract -->
            <DocumentModal :compId="company.id" :documentId="+data.item.id" edit @refresh="refresh"></DocumentModal>
            <b-button block class="mt-2" variant="danger" @click="deleteDocument(data.item)"><b-icon icon="trash"></b-icon> Delete</b-button>
            <a :href="data.item.downloadurl" class="text-decoration-none"><b-button block class="mt-2" variant="success"><b-icon icon="download"></b-icon> Download</b-button></a>
          </div>
        </template>
      </b-table>
      <b-pagination
        class="float-right"
        v-if="filters.totalItems > filters.max"
        v-model="filters.page"
        :total-rows="filters.totalItems"
        :per-page="filters.max"
        aria-controls="documentsTable"
        @change="changePage"
      ></b-pagination>
    </b-card>
  </b-container>
</template>
<script>
import DocumentService from '@/services/DocumentService'
import UserService from '@/services/UserService'
// import CompanyService from '@/services/CompanyService'
import moment from 'moment'

import DocumentModal from '@/components/admin/DocumentModal'
import BackButton from '@/components/BackButton'
import AdminService from '@/services/AdminService'

export default {
  data () {
    return {
      company: {
        id: 0,
        name: null,
        organization_id: 0,
        organization_name: ''
      },
      filters: {
        company_id: 0,
        year: moment().format('YYYY'),
        month: null,
        quarter: null,
        type: null,
        documenttype_id: null,
        allCompanies: false,
        order: {
          column: 'updated',
          direction: 'desc'
        },
        max: 15,
        page: 1,
        totalItems: 0
      },
      adminUser: false,
      bkUser: false,
      modalShow: false,
      selectedDocumentUrl: '',
      canEdit: false,
      loadingCount: 0,
      companies: [],
      allCompanies: [],
      selectedDocuments: [],
      files: [],
      types: [],
      years: [],
      months: [
        { text: '', value: null },
        { text: 'January', value: 1 },
        { text: 'February', value: 2 },
        { text: 'March', value: 3 },
        { text: 'April', value: 4 },
        { text: 'May', value: 5 },
        { text: 'June', value: 6 },
        { text: 'July', value: 7 },
        { text: 'August', value: 8 },
        { text: 'September', value: 9 },
        { text: 'October', value: 10 },
        { text: 'November', value: 11 },
        { text: 'December', value: 12 }
      ],
      quarters: [
        { text: '', value: null },
        { text: 'Q1', value: 1 },
        { text: 'Q2', value: 2 },
        { text: 'Q3', value: 3 },
        { text: 'Q4', value: 4 }
      ],
      doctypes: [],
      fields: [
        { key: 'select', label: '', sortable: false },
        { key: 'id', label: '', sortable: false },
        { key: 'documentname', label: 'Name', sortable: true },
        { key: 'documenttype_id', label: 'Type', sortable: true },
        { key: 'year', label: 'Year', sortable: true },
        { key: 'month', label: 'Month', sortable: true },
        { key: 'quarter', label: 'Quarter', sortable: true },
        { key: 'updated', label: 'Last Updated', sortable: true, sortDirection: 'desc' },
        { key: 'createdBy', label: 'Uploaded By', sortable: false },
        { key: 'updatedBy', label: 'Last Updated By', sortable: false }
      ]
    }
  },

  components: {
    DocumentModal,
    BackButton
  },

  props: {
    compId: {
      type: [Number, String],
      default: 0
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      this.setLoading(1)
      if (this.company.id === 0) {
        this.company.id = isNaN(this.compId) ? 0 : +this.compId
      }
      this.filters.company_id = this.company.id
      await UserService.getUserInfo().then(
        async user => {
          let getDocs = true
          let canEdit = false
          if (UserService.isAdmin()) {
            this.adminUser = true
            this.companies = await AdminService.getCompanies()
          } else {
            this.companies = await UserService.getCompanies()
          }
          if (UserService.isBookkeeper()) {
            this.allCompanies = await AdminService.getCompanies()
            this.bkUser = true
          }
          if (this.company.id === 0 && this.companies.length > 0) {
            this.company.id = +this.companies[0].id
            this.filters.company_id = this.company.id
          }
          let company = null
          if (!this.filters.allCompanies) {
            company = this.companies.filter((item) => { return +item.id === this.company.id })[0]
          } else if (this.filters.allCompanies) {
            company = this.allCompanies.filter((item) => { return +item.id === this.company.id })[0]
          }

          if (this.company.id === 0 || !company) {
            getDocs = false
            this.$aimNotify.error({ data: { redirect: '/' } }, 'error', 'Bad Company', 'You have selected an invalid company')
          } else {
            this.company.name = company.name
            canEdit = company.isBookkeeper
          }

          this.canEdit = canEdit

          if (canEdit) {
            this.fields.push({ key: 'action', label: '', sortable: false })
          }

          if (getDocs) {
            this.search()
          }

          DocumentService.createInit(0, this.company.id).then(
            (response) => {
              if (response && response.data && response.data.info) {
                this.doctypes = response.data.info.doctypes
                this.doctypes.unshift({ id: null, name: '' })
                this.years = response.data.info.years
              }
            }
          )

          this.setLoading(-1)
        }
      )
    },

    openModal (url) {
      this.selectedDocumentUrl = url
    },

    async bulkDelete () {
      await this.search()
      console.log('Updated files delete list:', this.files)
      this.$nextTick(async () => {
        // Check if files still exist before deleting
        const missingDocs = await this.getMissingDocuments()
        console.log('missing docs delete', missingDocs)
        if (missingDocs.length > 0) {
          this.showMissingDocumentsModal('delete', missingDocs)
        }
      })

      const confirm = await this.$bvModal.msgBoxConfirm(
        `You are about to delete ${this.selectedDocuments.length} documents. Are you sure?`, {
          title: 'Confirm Deletion',
          okVariant: 'danger',
          okTitle: 'DELETE',
          okDisabled: true,
          cancelTitle: 'Cancel',
          centered: true
        })

      if (!confirm) return

      this.setLoading(1)

      try {
        await DocumentService.bulkDelete(this.selectedDocuments)
        this.$aimNotify.notify({ type: 'success', message: 'Documents deleted successfully' })
        this.selectedDocuments = []
        await this.search()
      } catch (err) {
        this.$aimNotify.error(err.response)
      } finally {
        this.setLoading(-1)
      }
      // this.$bvModal.msgBoxConfirm(`You are about to delete ${this.selectedDocuments.length} documents. Are you sure?`, {
      //   title: 'Confirm Deletion',
      //   okVariant: 'danger',
      //   okTitle: 'DELETE'
      // }).then((confirm) => {
      //   if (confirm) {
      //     // const formData = new FormData()
      //     // formData.append('deleteDocIds', this.selectedDocuments)
      //     DocumentService.bulkDelete(this.selectedDocuments).then(
      //       () => {
      //         this.$aimNotify.notify({ type: 'success', message: 'Documents deleted successfully' })
      //         this.selectedDocuments = []
      //         this.search()
      //       },
      //       (err) => {
      //         this.$aimNotify.error(err.response)
      //       }
      //     )
      //   }
      // })
    },

    async bulkDownload () {
      await this.search()
      console.log('Updated files list:', this.files)
      this.$nextTick(async () => {
        // Check if files still exist before downloading
        const missingDocs = await this.getMissingDocuments()
        console.log('missing docs', missingDocs)
        if (missingDocs.length > 0) {
          this.showMissingDocumentsModal('download', missingDocs)
          return
        }

        this.selectedDocuments.forEach(docId => {
          const doc = this.files.find(d => d.id === docId)
          if (doc && doc.downloadurl) {
            window.open(doc.downloadurl, '_blank')
          }
        })
      })
    },

    async getMissingDocuments () {
      const existingDocIds = this.files.map(doc => doc.id)
      return this.selectedDocuments.filter(docId => !existingDocIds.includes(docId))
    },

    showMissingDocumentsModal (actionType, missingDocs) {
      this.$bvModal.msgBoxOk(
        `The following document(s) no longer exist and cannot be ${actionType}ed:\n\n${missingDocs.join(', ')}`, {
          title: 'Document(s) Not Found',
          size: 'md',
          okTitle: 'OK',
          okVariant: 'primary',
          centered: true
        })

      this.search()
    },

    toggleModal (url) {
      this.modalShow = !this.modalShow
      if (this.modalShow) {
        this.openModal(url)
      }
    },

    changePage (page) {
      this.filters.page = page
      this.search()
    },

    search () {
      this.setLoading(1)
      DocumentService.search(this.filters).then(
        (response) => {
          this.files = response.data.info.results
          this.filters.totalItems = response.data.info.totalResults
        }
      ).finally(() => { this.setLoading(-1) })
    },

    deleteDocument (document) {
      const docname = document.documentname || ' document ' + document.id
      this.$bvModal.msgBoxConfirm('You are about to delete ' + docname, {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'DELETE'
      })
        .then(
          (value) => {
            if (value) {
              DocumentService.delete(document.id).then(
                (response) => {
                  this.$aimNotify.notify(response)
                },
                (err) => {
                  this.$aimNotify.error(err.response)
                }
              ).finally(() => { this.search() })
            }
          }
        )
    },

    refresh () {
      this.filters.page = 1
      this.filters.totalItems = 0
      this.search()
    },

    selectCompany (value) {
      this.$router.push('/documents/' + value, () => {
        this.company.id = +value
        this.init()
      })
    },

    sortingChange (tableObj) {
      this.filters.order.column = tableObj.sortBy
      this.filters.order.direction = tableObj.sortDesc ? 'desc' : 'asc'

      this.filters.page = 1
      this.filters.totalItems = 0

      this.search()
    },

    setLoading (amount) {
      if (amount > 0) {
        amount = 1
      }

      this.loadingCount += amount

      if (this.loadingCount < 0) {
        this.loadingCount = 0
      }
    },

    monthByInt (val) {
      const month = this.months.filter(el => { return +el.value === +val })
      if (month.length > 0) {
        return month[0].text
      } else {
        return ''
      }
    },

    resetfilters () {
      this.filters = {
        company_id: this.company.id,
        year: moment().format('YYYY'),
        month: null,
        quarter: null,
        type: null,
        documenttype_id: null,
        order: {
          column: 'updated',
          direction: 'desc'
        },
        max: 15,
        page: 1,
        totalItems: 0
      }

      this.search()
    },

    isPdf (url) {
      const pdfRegex = /.pdf/i
      return pdfRegex.test(url)
    }

  },

  computed: {
    loading () {
      return this.loadingCount > 0
    },
    dropdownOptions () {
      return this.filters.allCompanies ? this.allCompanies : this.companies
    }
  },

  filters: {
    quarter (val) {
      if (val && !isNaN(val)) {
        return 'Q' + val
      } else {
        return ''
      }
    },

    date (val) {
      return moment(val).format('YYYY-MM-DD')
    },

    docname (val) {
      if (val && val !== '') {
        return val
      } else {
        return 'NO DOCUMENT ATTACHED'
      }
    }
  }
}
</script>

<style scoped>
.sticky-filters {
  display: block;
  width: 100%;
  position: sticky;
  top: 0;
  left: 15px;
  z-index: 100;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
</style>
