<template>
  <b-overlay
    :show="loading"
    v-if="transactions"
  >
    <b-row>
      <b-col class="text-left">
        <h2>{{ heading }}</h2>
      </b-col>
    </b-row>

    <b-table
      :id="tableName"
      :fields="headers"
      :items="transactions"
      :sort-by.sync="sort.column"
      :sort-desc.sync="sort.reverse"
      bordered
      sticky-header="600px"
      class="small"
      show-empty
      primary-key="id"
      tbody-tr-class="align-middle"
      details-td-class="w-100 p-0"
      tfoot-tr-class="align-middle"
      striped
      @row-clicked="(item) => toggleDetailRow(item)"
      @sort-changed="(ctx) => $emit('sort-changed', ctx)"
    >
      <template #cell(qbtype)="data">
        {{ data.item.qbtype.replace(/([A-Z])/g, ' $1') }}
      </template>

      <template #cell(amount)="data">
        <strong>{{ data.item.amount | currency }}</strong>
      </template>

      <template #cell(started)="data">
        <span v-if="data.item.messagesunread > 0"><strong>Unread ({{ data.item.messagesunread }})</strong></span>
        <span v-else-if="!data.item.messages.length">Needs Info</span>
        <span v-else-if="data.item.messagesneedresponse">Needs Response</span>
        <span v-else>Responded</span>
      </template>

      <template #cell(id)="data">
        <b-icon
          :icon="(data.detailsShowing) ? 'chevron-up' : 'chevron-down'"
          class="pointer"
        >
        </b-icon>
      </template>

      <template #row-details="row">
        <Messaging
          :transaction="row.item"
          :role="role"
          @toggle="row.toggleDetails"
          @update="updateMessage"
          @refresh="$emit('refresh')"
        ></Messaging>
      </template>

      <template
        #custom-foot="data"
        v-if="total > 0"
      >
        <b-tr class="align-middle">
          <b-td :colspan="+data.columns">
            <TransactionPagination
              class="m-0"
              :current-page="currentPage"
              :per-page="perPage"
              :total-records="total"
              :table-name="tableName"
              @update-page="(v) => { $emit('update-page', v) }"
              @update-per-page="(v) => { $emit('update-per-page', v) }"
            ></TransactionPagination>
          </b-td>
        </b-tr>
      </template>
    </b-table>
  </b-overlay>
</template>
<script>
import TransactionPagination from '@/components/bookkeeping/widgets/TransactionPagination'
import Messaging from '@/components/feedback/Messaging'

export default {
  name: 'TransactionList',
  components: { Messaging, TransactionPagination },
  props: {
    transactions: {
      type: Array,
      default () { return [] }
    },
    status: {
      type: Object,
      default () { return null }
    },
    amount: {
      type: Number,
      default () { return 0 }
    },
    total: {
      type: Number,
      default () { return 0 }
    },
    currentPage: Number,
    perPage: Number,
    tableName: String,
    heading: String,
    sort: {
      type: Object,
      default () { return null }
    },
    role: {
      type: String,
      default: 'user'
    }
  },

  data () {
    return {
      loading: false,
      updated: false,
      headers: [
        {
          key: 'businessdate',
          label: 'Date',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'linkedqbaccount',
          label: 'Source',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbtype',
          label: 'Type',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'qbentity',
          label: 'Vendor',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true,
          tdClass: 'align-middle text-right',
          thClass: 'text-right'
        },
        {
          key: 'started',
          label: 'Status',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'id',
          label: '',
          tdClass: 'align-middle'
        }
      ]
    }
  },

  methods: {
    updateMessage (msg) {
      this.updated = true
      this.$emit('update', msg)
    },

    toggleDetailRow (item) {
      this.$set(item, '_showDetails', !item._showDetails)
      if (this.updated) {
        this.updated = false
        this.$emit('refresh')
      }
    }
  }
}
</script>
