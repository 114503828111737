<template>
  <b-row class="h-100">
    <b-col
      cols="12"
      sm="6"
      class="text-left"
    >
      {{ startingRecord }} - {{ endingRecord }} of {{ totalRecords }} transaction<span v-if="totalRecords > 1">s</span>
    </b-col>
    <b-col
      cols="12"
      sm="6"
    >
      <b-row
        class="align-middle"
        align-h="end"
      >
        <b-col cols="auto">
          <b-form-group
            label="Per page: "
            label-for="per-page-select"
            label-align="right"
            label-size="sm"
            class="mb-0 form-inline"
          >
            <b-form-select
              id="per-page-select"
              v-model="form.perpage"
              :options="perPageOptions"
              size="sm"
              class="ml-1"
              @change="(v) => { $emit('update-per-page', v) }"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="auto">
          <b-pagination
            v-model="form.page"
            :total-rows="totalRecords"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
            :aria-controls="tableName"
            @input="(v) => { $emit('update-page', v) }"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'TransactionPagination',

  props: {
    tableName: String,
    currentPage: Number,
    perPage: Number,
    totalRecords: Number,
    perPageOptions: {
      type: Array,
      default () { return [10, 20, 30] }
    }
  },

  data () {
    return {
      form: {
        page: 1,
        perpage: 10
      }
    }
  },

  mounted () {
    if (this.currentPage) {
      this.form.page = this.currentPage
    }
    if (this.perPage) {
      this.form.perpage = this.perPage
    }
  },

  // watch for upstream changes
  watch: {
    currentPage: function (n, o) {
      if (n && n !== o) {
        this.form.page = +n
      }
    },

    perPage: function (n, o) {
      if (n && n !== o) {
        this.form.perpage = +n
      }
    }
  },

  computed: {
    startingRecord: function () {
      return +this.perPage * (+this.currentPage - 1) + 1
    },
    endingRecord: function () {
      return (+this.perPage * +this.currentPage > +this.totalRecords) ? +this.totalRecords : +this.perPage * +this.currentPage
    }
  }
}
</script>
