<template>
  <b-overlay :show="loading">
    <b-container fluid>
      <b-row class="mb-4">
        <b-col class="text-left text--white">
          <h4>Transaction Feedback</h4>
          <b-row>
            <b-col cols="6"  v-if="this.role !== 'user'">
              <div class="mb-4 mt-6">
                <input
                  v-model="searchBKQuery"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="employee_search"
                  type="text"
                  placeholder="Enter Employee Name"
                  @focus="showBookKeeperList()"
                  @blur="hideBKList()"
                >
                <ul v-if="showBKList" class="absolute z-10 w-full mt-1 rounded-md shadow-lg max-height-60 overflow-y-scroll list-none no-list-style custom-bg-gray custom-text-gray">
                  <li
                    v-for="(employee, index) in filteredEmployees"
                    :key="`employee-${employee.id}-${index}`"
                    @click="updateSelectedEmployee(employee)"
                    class="cursor-pointer p-3 custom-bg-gray custom-text-gray">
                    {{ employee.fullname }}
                  </li>
                </ul>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="mb-4 mt-6">
                <input
                  v-model="searchQuery"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="company_search"
                  type="text"
                  placeholder="Enter Company Name"
                  @focus="showCompanyList()"
                  @blur="hideList()"
                >
                <ul v-if="showList" class="absolute z-10 w-full mt-1 rounded-md shadow-lg max-height-60 overflow-y-scroll list-none no-list-style custom-bg-gray custom-text-gray">
                  <li
                    v-for="(company, index) in filteredCompanies"
                    :key="`company-${company.id}-${index}`"
                    @click="updateSelectedCompany(company)"
                    class="cursor-pointer p-3 custom-bg-gray custom-text-gray">
                    {{ company.name }}
                    <b-badge v-if="company.unread" style="background-color: #BE191E; color: white;">
                      {{ company.unread }} Unread
                    </b-badge>
                  </li>
                </ul>
              </div>
            </b-col>
          </b-row>
          <div
            class="form-inline"
            v-if="companies.length > 1"
          >
            <!-- <b-form-select
              v-model="selectedCompanyId"
              @change="search">
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Company</b-form-select-option>
              </template>
              <b-form-select-option
                v-for="company in sortedCompaniesWithUnread()"
                :key="company.id"
                :value="company.id"
              >
                {{ company.name }} <span class="text-red-600 font-bold">({{ company.unread }} Unread)</span>
              </b-form-select-option>
              <b-form-select-option
                v-for="company in sortedCompaniesWithNoUnread()"
                :key="company.id"
                :value="company.id"
              >
                {{ company.name }}
              </b-form-select-option>
            </b-form-select> -->

            <!--
            <b-dropdown :text="selectedCompanyText" v-model="selectedCompanyText" variant="white" class="bg-white text-dark">
              <b-dropdown-item
                v-for="company in companies"
                :key="company.id"
                @click="updateSelectedCompany(company)"
              >
                {{ company.name }}
                <b-badge v-if="company.unread" variant="primary">
                  {{ company.unread }}
                </b-badge>
              </b-dropdown-item>
            </b-dropdown> -->
          </div>
        </b-col>
        <b-col class="text-right">
          <b-button
            pill
            variant="outline-secondary"
            @click.prevent="search"
            :disabled="loading"
          >
            <b-icon-arrow-counterclockwise class="align-middle"></b-icon-arrow-counterclockwise>
          </b-button>
        </b-col>
      </b-row>

      <b-card
        v-if="results && searched"
        bg-variant="light"
        class="text--black"
      >
        <TransactionList
          :transactions="transactions"
          :status="status"
          :amount="amount"
          :total="total"
          :current-page="currentPage"
          :per-page="perPage"
          :sort="sort"
          :role="role"
          table-name="needsinfoTable"
          @refresh="search"
          @update="updateMessage"
          @update-page="updatePage"
          @update-per-page="updatePerPage"
          @sort-changed="updateSort"
          heading="Transactions Requiring Feedback"
        ></TransactionList>
      </b-card>
    </b-container>
  </b-overlay>
</template>
<script>
import UserService from '@/services/UserService'
import FeedbackService from '@/services/FeedbackService'
import TransactionList from '@/components/feedback/TransactionList'
import AdminService from '@/services/AdminService'

export default {
  name: 'Feedback',
  components: { TransactionList },
  data () {
    return {
      loading: false,
      searched: false,
      updated: true,
      allCompanies: [],
      companies: [],
      selectedCompanyId: null,
      results: [],
      transactions: [],
      status: null,
      total: 0,
      amount: 0,
      currentPage: 1,
      perPage: 10,
      sort: {
        column: 'businessdate',
        reverse: false
      },
      role: '',
      selectedCompanyText: 'Select Company',
      searchQuery: '',
      showList: false,
      searchBKQuery: '',
      showBKList: false,
      bookeeper_id: 0,
      employees: []
    }
  },

  computed: {
    companiesWithUnread () {
      return this.allCompanies.filter(company => company.unread > 0)
    },
    companiesWithNoUnread () {
      return this.allCompanies.filter(company => company.unread === 0)
    },
    filteredCompanies () {
      return this.searchQuery ? this.companies.filter(e => e.name.toLowerCase().includes(this.searchQuery.toLowerCase())) : this.companies
    },
    filteredEmployees () {
      return this.searchBKQuery ? this.employees.filter(e => e.fullname.toLowerCase().includes(this.searchBKQuery.toLowerCase())) : this.employees
    }
  },

  async mounted () {
    await this.getBookkeepers()
    await this.init()
    this.autoSelectEmployee()
  },

  methods: {
    async init () {
      if (UserService.isAdmin()) {
        this.role = 'admin'
      } else {
        if (UserService.isBookkeeper()) {
          this.role = 'bookkeeper'
        } else {
          this.role = 'user'
        }
      }
      if (this.role === 'user') {
        this.allCompanies = await AdminService.getCompaniesForClient()
        // console.log('from user')
        // console.log(this.allCompanies.filter(company => company.unread > 0))
      } else {
        this.allCompanies = await AdminService.getFeedbackCompanies()
      }
      console.log(this.allCompanies.filter(company => company.unread > 0))
      this.sortCompanies()
      this.filterCompanies()
    },

    async getBookkeepers () {
      if (UserService.isAdmin()) {
        this.role = 'admin'
      } else {
        if (UserService.isBookkeeper()) {
          this.role = 'bookkeeper'
        } else {
          this.role = 'user'
        }
      }
      return AdminService.getBookkeepers().then(
        (response) => {
          if (response && response.data && response.data.info && response.data.info.length) {
            const filteredInfo = response.data.info.filter(bookkeeper => bookkeeper.role_id === 20)
            this.employees = [{ id: 0, fullname: 'All Bookkeepers', role: 'Administrator' }, ...filteredInfo.sort((a, b) => ('' + a.fullname).localeCompare(b.fullname))]
            UserService.getUserInfo().then(
              user => {
                this.employees.some(e => {
                  if (e.id === user.id) {
                    this.bookeeper_id = e.id
                    return true
                  } else {
                    this.bookeeper_id = 0
                  }
                  return false
                })
              }
            )
          }
        }
      )
    },

    autoSelectEmployee () {
      const selectedEmployee = this.employees.find(e => e.id === this.bookeeper_id)
      if (selectedEmployee) {
        this.searchBKQuery = selectedEmployee.fullname
      }
    },

    updateSelectedEmployee (employee) {
      this.bookeeper_id = employee.id
      if (employee.role === 'Administrator') {
        this.role = 'admin'
      } else if (employee.role === 'Bookkeeper') {
        this.role = 'bookkeeper'
      } else {
        this.role = 'user'
      }
      this.searchBKQuery = employee.fullname
      this.searchQuery = ''
      this.showBKList = false
      this.filterCompanies()
    },

    sortCompanies () {
      const companiesUnread = this.sortedCompaniesWithUnread()
      const companiesNoUnread = this.sortedCompaniesWithNoUnread()
      this.allCompanies = [...companiesUnread, ...companiesNoUnread]
      if (this.allCompanies.length === 1) {
        this.selectedCompanyId = +this.allCompanies[0].id
        this.search()
      }
    },

    async filterCompanies () {
      if (this.bookeeper_id === null || this.bookeeper_id === 0) {
        // if (this.role === 'user') {
        //   const user = await UserService.getUserInfo()
        //   console.log(user)
        //   this.companies = []
        //   user.roles.forEach(role => {
        //     if (role.company_id && role.company_id > 0) {
        //       this.companies.push(...this.allCompanies.filter(company => company.id === role.company_id))
        //     } else if (role.organization_id && role.organization_id > 0) {
        //       this.companies.push(...this.allCompanies.filter(company => company.organization_id === role.organization_id))
        //     }
        //   })
        //   console.log(this.companies)
        //   const uniqueCompanies = []
        //   const uniqueCompanyIds = new Set()
        //   this.companies.forEach(company => {
        //     if (!uniqueCompanyIds.has(company.id)) {
        //       uniqueCompanyIds.add(company.id)
        //       uniqueCompanies.push(company)
        //     }
        //   })
        //   this.companies = uniqueCompanies
        // } else {
        this.companies = this.allCompanies
        // }
      } else {
        this.companies = this.allCompanies.filter(company => company.bookkeeper_id && company.bookkeeper_id === this.bookeeper_id)
      }
    },

    sortedCompaniesWithUnread () {
      return this.companiesWithUnread.slice().sort((a, b) => a.name.localeCompare(b.name))
    },

    sortedCompaniesWithNoUnread () {
      return this.companiesWithNoUnread.slice().sort((a, b) => a.name.localeCompare(b.name))
    },

    hideList () {
      setTimeout(() => {
        this.showList = false
      }, 400)
    },

    hideBKList () {
      setTimeout(() => {
        this.showBKList = false
      }, 400)
    },

    showBookKeeperList () {
      this.searchBKQuery = ''
      this.showBKList = true
    },

    showCompanyList () {
      this.searchQuery = ''
      this.showList = true
    },

    updateSelectedCompany (company) {
      console.log('called funtion selected company')
      this.selectedCompanyId = company.id
      this.selectedCompanyText = `${company.name} (${company.unread} unread)`
      this.searchQuery = `${company.name} (${company.unread} unread)`
      this.showList = false
      this.search()
    },

    updatePage (page) {
      this.currentPage = page
      this.$nextTick(() => {
        this.search()
      })
    },

    updatePerPage (perpage) {
      this.perPage = perpage
      this.$nextTick(() => {
        this.search()
      })
    },

    updateSort (sort) {
      this.sort.column = sort.sortBy
      this.sort.reverse = (!sort.sortDesc)
      if (this.total > this.perPage) {
        this.$nextTick(() => {
          this.search()
        })
      }
    },

    search () {
      if (this.loading || !this.selectedCompanyId) {
        return false
      }

      const data = {
        company_id: +this.selectedCompanyId,
        feedback: true,
        isclient: true,
        page: this.currentPage,
        perPage: this.perPage,
        sort: this.sort
      }

      this.loading = true
      FeedbackService.search(data).then((response) => {
        if (response && response.data.result) {
          this.transactions = response.data.info.transactions
          this.total = +response.data.info.total
          this.amount = response.data.info.amount
          this.status = response.data.info.status
          this.searched = true
        }
      }).finally(() => {
        this.loading = false
      })
    },

    updateMessage (msg) {
      let transaction = null
      this.transactions.needsinfo.some((t) => {
        if (+t.id === +msg.plaidtransaction_id) {
          transaction = t
          return true
        } else return false
      })
      if (!transaction) {
        this.transactions.responded.some((t) => {
          if (+t.id === +msg.plaidtransaction_id) {
            transaction = t
            return true
          } else return false
        })
      }
      if (transaction) {
        let updated = false
        transaction.messages.some((m, idx) => {
          if (+m.id === +msg.id) {
            transaction.messages[idx] = msg
            transaction.messagesunread--
            updated = true
            return true
          } else return false
        })
        if (updated) {
          this.updated = updated
        }
      }
    }
  }
}
</script>

<style scoped>
.max-height-60 {
  max-height: 25rem;
}
.overflow-y-scroll {
  overflow-y: auto;
}
.no-list-style {
  list-style-type: none;
  padding-left: 0;
  max-height: 25rem;
  overflow-y: auto;
}
.custom-bg-gray {
  background-color: #202225;
}

.custom-text-gray {
  color: #e0e5ec;
}
</style>
